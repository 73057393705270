import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useClasses } from '../../customHooks';


const styles = (theme) => ({
    sidebarAboutBox: {
        marginTop: theme.spacing(20),
        padding: theme.spacing(2),
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
    },
});

export default function Sidebar(props) {
    const classes = useClasses(styles);
    const { archives, description, title } = props;

    return (
        <Grid item>
            <Paper elevation={0} className={classes.sidebarAboutBox}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Typography>{description}</Typography>
            </Paper>
            <Paper elevation={1} className={classes.sidebarSection}>
                <Typography variant="h6" gutterBottom>
                    Archives
                </Typography>
                {archives.map((archive) => (
                    <Link display="block" variant="body1" href={archive.url} key={archive.title}>
                        {archive.title}
                    </Link>
                ))}
            </Paper>
        </Grid>
    );
}

Sidebar.propTypes = {
    archives: PropTypes.array,
    description: PropTypes.string,
    title: PropTypes.string,
};