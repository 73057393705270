import React, { Component } from 'react';
import SignUp from '../../components/SignUp';
import './styles.scss';

class Registration extends Component {
    render(){
        return (
            <SignUp/>
        );
    }
}

export default Registration;
