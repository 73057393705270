import React from 'react';
import ShoppingCart from '../../components/ShoppingCart';

const Cart = ({}) => {
    return (
        <div>
            <ShoppingCart />
        </div>
    );
}

export default Cart;