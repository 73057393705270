import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useClasses } from '../../customHooks';

const styles = (theme) => ({
    root: {
        marginTop: theme.spacing(10),
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(8),
        marginRight: theme.spacing(8),
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        backgroundColor: '#FBF5E6',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
});

export default function QuestionCard() {
    const classes = useClasses(styles);

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <div >
                    <CardContent className={classes.content}>
                        <Typography component="h6" variant="h6">
                            Question: What is the purpose of an SUV Tent?
                        </Typography>
                        <Typography variant="subtitle1" color="textPrimary">
                        Answer: We found &nbsp; 
                        <Link href="https://fourwheeltrends.com/what-is-the-purpose-of-an-suv-tent/" target="_blank" rel="noreferrer">
                        this article
                        </Link>
                        &nbsp;very helpful.
                        <br/>                            
                            "The SUV Tent lets you sleep off the ground in the comfort of your own vehicle. The tent connects to the back of any size SUV, minivan, wagon, or pick-up truck with cap. Large size no-see-um mesh windows and doors have storm covers that can be closed for privacy."
                        </Typography>
                    </CardContent>
                </div>
            </Card>
            <Card className={classes.card}>
                <div >
                    <CardContent className={classes.content}>
                        <Typography component="h6" variant="h6">
                            Question: Are guidelines and stakes included for the expandable awning?
                        </Typography>
                        <Typography variant="subtitle1" color="textPrimary">
                            Answer: The Armadillo V1 Tent comes with ropes and tent nails, which you can use to secure the awning to the ground. However, it does not come with poles for attaching the guidelines to. You can also directly tie the ropes to nearby trees, which will serve the same function as tent poles.
                        </Typography>
                    </CardContent>
                </div>
            </Card>
            <Card className={classes.card}>
                <div >
                    <CardContent className={classes.content}>
                        <Typography component="h6" variant="h6">
                            Question: How do I know whether this tent will fit my car?
                        </Typography>
                        <Typography variant="subtitle1" color="textPrimary">
                            Answer: The Armadillo V1 Tent is designed to be universal fit. As long as your car is an SUV with vertically-opening trunk door, it should fit. We have tested this tent on small SUVs, mid-sized SUVs, and also trucks with camper shells, and they all fit with no issues.
                        </Typography>
                    </CardContent>
                </div>
            </Card>
            <Card className={classes.card}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h6" variant="h6">
                            Question: What’s the waterproof rating and/or thickness?
                        </Typography>
                        <Typography variant="subtitle1" color="textPrimary">
                            Answer: The Armadillo V1 Tent has a hydrostatic head count of 1000.
                        </Typography>
                    </CardContent>
                </div>
            </Card>
            <Card className={classes.card}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h6" variant="h6">
                            Question: Does this product come with instructions?
                        </Typography>
                        <Typography variant="subtitle1" color="textPrimary">
                            Answer: Yes, this product comes with a user manual, which will explain the detailed steps of installing the tent on your vehicle. Here is the download link for the manual.
                        </Typography>
                    </CardContent>
                </div>
            </Card>
            <Card className={classes.card}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h6" variant="h6">
                            Question: Do you plan to sell other prepping products?
                        </Typography>
                        <Typography variant="subtitle1" color="textPrimary">
                            Answer: Yes, we are working on developing other products related to prepping. The Armadillo V1 Tent is our first product. You are also welcome to contact us if you want to see any products to be made. Suggestions are welcome!  
                        </Typography>
                    </CardContent>
                </div>
            </Card>
        </div>
    );
}