import React from 'react';
import Typography from '@mui/material/Typography';
import ReactPlayer from 'react-player'
import Grid from '@mui/material/Grid';
import { useClasses } from '../../customHooks';

const styles = (theme) => ({
  dataGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  card: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
});

function Videos() {
  const classes = useClasses(styles);

  return (
    <>
      <div className={classes.dataGrid} style={{ backgroundColor: 'white' }}>
        <Typography variant='h5' style={{ fontWeight: 600, }} align='center'>
          Check Out Our Videos
        </Typography>
        <div className={classes.card}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={11} md={5} >
            <ReactPlayer alt='Demo Video' width='100%' url={'https://www.youtube.com/watch?v=5d82RR6g0uA'} />
          </Grid>
          <Grid item xs={11} md={5} >
            <ReactPlayer alt='Setup Video' width='100%' url={'https://www.youtube.com/watch?v=WogCptJ8GX8'} />
          </Grid>
        </Grid>
        </div>
      </div>
    </>
  );
}

export default Videos;