import React from 'react';
import BlogMetaDecorator from "../../utils/BlogMetaDecorator";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const MinimalistBlog = () => {
    return (
        <div>
            <BlogMetaDecorator title="Minimalist SUV Camping Check List" image="demo3.jpg" imageAlt="camping tent" folder="camping" description="This list can be used to prepare for regular road tripping by car, car camping, or emergency bugging out." />
            <h3> Sep 1, 2021 by June </h3>

            <Divider />

            <Typography paragraph style={{marginTop: 8}}> This article was written after a 28-day road trip we did to the eastern coast US August 2021. We crossed the states of MO -> KY -> TN -> NC -> VA -> MD -> PA -> WV -> OH -> IN -> IL -> WI -> MO with our Subaru Outback. We changed our location almost every other day. Traveling by car and camping in the car saved us a lot of money. According to our experience, we summarized this “Minimalist Car Camping List”.

            Although the major purpose for us to do minimalist car travelling was to save money, it also benefited us for other reasons: keeping it all simple and lightweight, saving a lot of energy from packing and unpacking, and being able to relax and enjoy nature more. Practicing this minimalist travel style was helpful for us in preparing for future emergent scenarios. If we have a camping package ready, it will be super easy for us to bug out.

            As opposed to “doomsday” prepping, we are assuming that you will have a vehicle and basic resources like gas and water. We also believe in seeking a level of comfort, beyond mere survival. This list does not cover any knowledge about how to find water, obtain food, or use weapons. The optional items in the list can be adjusted based on personal needs.

            This list can be used to prepare for regular road tripping by car, car camping, or emergency bugging out. </Typography>

            <h3> 1. Shelter </h3>
            <ul>
                <li>SUV trunk tent--it is compact, can be easily set up or removed, and is inconspicuous.</li>
            </ul>

            <h3> 2. Sleeping System </h3>

            <Typography paragraph> A good sleep is very important, as it keeps us physically and mentally healthy. A sharp mind can make good decisions, which is critical for long-term survival. </Typography>
            <ul>
                <li>Memory foam mattress--it usually fits better than air mattress in vehicles.</li>
                <li>Sleeping bags--are good insulator to retaining warmth.</li>
                <li>Hammocks (optional)-- great for napping or relaxing.</li>
            </ul>

            <h3> 3. Hygiene </h3>
            <ul>
                <li>Wet wipes--in case a shower is not possible, can use them to clean.</li>
                <li>Full-body pop-up shower tent--very handy, in case the place you stay has no showers; the full-body version is completely enclosed, so you don’t need to worry about your feet getting muddy on bare ground.</li>
                <li>Solar water bag with shower head--this type of water heater can heat up the water under the sun. You just need to fill it in the morning, put it under the sun, and you can enjoy a hot shower at night.</li>
            </ul>
            
            <h3> 4. Cooking Utensils </h3>
            <ul>
                <li>Knife set</li>
                <li>Pot--for boiling</li>
                <li>Pan--for frying food</li>
                <li>Collapsible wash basin--can be used to wash fruits and vegetables; when collapsed, can be used as a cutting board</li>
                <li>Ziplock bags—avoids exposing food, which may attract wild animals</li>
                <li>Eating utensils--two bowls, two cups, two forks/spoons</li>
                <li>Sponge/wash cloth</li>
                <li>Dish soap</li>
                <li>Camp stove and propane gas--cooking by gas is fast, energy efficient and enviornmental friendly, you can also start your own fire to cook or grill</li>
                <li>Lighter</li>
                <li>Trash bags--need proper way to store trash, so it does not attract wild animals; take the trash to dumpsters in urban areas if possible</li>
                <li>Large insulated cooler bag--to store food that needs to be preserved under cool temperatures</li>
            </ul>

            <h3> 5. Clothing </h3>
            <ul>
                <li>Regular clothes you wear daily</li>
                <li>Hiking socks--spare socks are necessary, wool socks</li>
                <li>Hiking boots</li>
                <li>Waterproof jacket</li>
                <li>Water shoes--for aquatic activities</li>
                <li>Bathing suit--for aquatic activities</li>
                <li>Hat</li>
                <li>Sunglasses</li>
                <li>Towel</li>
                <li>Clothesline--to dry your towel and clothes</li>
            </ul>

            <h3> 6. Food and Water </h3>
            <Typography paragraph>You can prepare food when you are close to cities, especially food that’s easy to transport and does not easily spoil, such as rice, noodles, and cookies</Typography>
            <ul>
                <li>Hunting tools (optional)--for long-term off-grid living</li>
                <li>Fishing tools (optional)</li>
                <li>Container for water--Water bottle / hydration pack / water filter</li>
            </ul>

            <h3> 7. Toiletries </h3>
            <ul>
                <li>Shower gel</li>
                <li>Toothpaste</li>
                <li>Toothbrush</li>
                <li>Lotion (optional)</li>
                <li>Sunscreen</li>
                <li>Nail clippers</li>
                <li>Tweezers—for ticks</li>
                <li>Insect repellent</li>
                <li>Toilet paper</li>
            </ul>

            <h3> 8. Electronics </h3>
            <ul>
                <li>Camping lamp / flashlight / headlight--red light is helpful to keep mosquitos away</li>
                <li>Spare rechargeable batteries for the lights</li>
                <li>Mobile phone—pre-download the off-line map to your smart phone; it can still detect your location using GPS without a phone signal</li>
                <li>Other communication tools (optional)--ham radio, walkie-talkies</li>
                <li>Other electronics for entertainment (optional)--tablet / laptop / Switch / Kindle, based on personal need</li>
                <li>Solar system with battery bank--for charging the electronics</li>
                <li>USB cables--spare charging cables</li>
            </ul>
 
            <h3> 9. Other Gear </h3>
            <ul>
                <li>Table and chairs--prefer lightweighted, easy-to-carry, foldable table and chairs; tabletop comes handy for outdoor cooking</li>
                <li>Picnic blanket with waterproof bottom</li>
                <li>Day bag--for carrying items when hiking</li>
                <li>Tools such as durable hammer and shovel</li>
            </ul>

            <h3> 10. Other Emergency Essentials </h3>
            <ul>
                <li>First-aid kit</li>
                <li>Personal medicine</li>
                <li>Pain relief medication</li>
                <li>Allergy relief medication</li>
                <li>Whistle</li>
                <li>Life jacket (optional)</li>
            </ul>

            <h3> 11. Storage </h3>
            <ul>
                <li>
                    Rooftop cargo box (optional)--extra storage space could make a big difference
                </li>
            </ul>

            <b>Please feel free to download our check list here: </b>
            <a href='/check_list_SUV_camping.pdf' target='_blank' color='yellow' rel='noreferrer'>PDF</a>

        </div>
    );
}

export default MinimalistBlog;