import React from 'react';
import Btn from '@mui/material/Button';

const Button = ({ children, ...otherProps }) => {
    return (
        <Btn 
            fullWidth
            variant="contained" 
            color="primary"
            className="btn" 
            size="large" 
            style={{
                fontSize: 14
            }}
            {...otherProps}>
            {children}
        </Btn>
    );
}

export default Button;