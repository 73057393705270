import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCartItems, selectCartTotal } from '../../redux/Cart/cart.selector';
import { createStructuredSelector } from 'reselect';
import Button from '../forms/Button';
import Item from './Item';
import PaymentIcon from '@mui/icons-material/Payment';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {
    Table, TableHead,
    TableBody, TableRow, TableCell
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useClasses } from '../../customHooks';

const mapState = createStructuredSelector({
    cartItems: selectCartItems,
    total: selectCartTotal
});

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: {
        paddingLeft: theme.spacing(5),
        paddingTop: theme.spacing(10),
    },
});

const ShoppingCart = ({ }) => {
    const history = useHistory();
    const classes = useClasses(styles);
    const errMsg = "Your shopping cart is currently empty.";
    const { cartItems, total } = useSelector(mapState);

    const configCheckoutButton = {
        startIcon: <PaymentIcon />,
    };

    const configShopButton = {
        startIcon: <LocalMallIcon />,
    };

    return (
        <React.Fragment>
            <Typography className={classes.title} component="h2" variant="h6" color="primary" gutterBottom spacing={3} margin={3} >
                {'Shopping Cart'}
            </Typography>
            {cartItems.length > 0 ? (
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {'Product Image'}
                                            </TableCell>
                                            <TableCell>
                                                {'Product Name'}
                                            </TableCell>
                                            <TableCell>
                                                {'Quantity'}
                                            </TableCell>
                                            <TableCell>
                                                {'Price'}
                                            </TableCell>
                                            <TableCell>
                                                {'Remove'}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cartItems.map((item, pos) => {
                                            return (
                                                <TableRow key={pos}>
                                                    <Item {...item} />
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} >
                            <h3>
                                Total: ${total}
                            </h3>   
                        </Grid>
                        <Grid item xs={0} sm={2} />
                        <Grid item container xs={12} sm={4}>
                            <Button {...configShopButton} onClick={() => history.push('/search')}>
                                Continue Shopping
                            </Button>
                        </Grid>
                        <Grid item container xs={12} sm={4}>
                            <Button {...configCheckoutButton} onClick={() => history.push('/payment')}>
                                Checkout
                            </Button>
                        </Grid>
                        <Grid item xs={0} sm={2} />
                    </Grid> 
                </Container>
            ) : (
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h3>
                                {errMsg}
                            </h3>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        <Button {...configShopButton} onClick={() => history.push('/search')}>
                        Go Back to Product Page
                        </Button>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        <Button onClick={() => history.push('/')}>
                        Go Back to Home Page
                        </Button>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </React.Fragment >
    );
};

export default ShoppingCart;