import { Typography } from '@mui/material';
import React from 'react';
import { useClasses } from '../../customHooks';

const styles = (theme) => ({
    root: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(10),
    }
});

const Return = () => {
    const classes = useClasses(styles);

    return (
        <div className={classes.root}>
            <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                Return Policy
            </Typography>
            <Typography paragraph>
                We have a 20-day return policy, which means you have 20 days after receiving your item to request a return.
            </Typography>
            <Typography paragraph>
                To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.
            </Typography>
            <Typography paragraph>
                To start a return, you can contact us at info@everybodypreps.com. If your return is accepted, we’ll send you a return shipping label, as well as instructions on how and where to send your package. Items sent back to us without first requesting a return will not be accepted.
            </Typography>
            <Typography paragraph>
                You can always contact us for any return question at info@everybodypreps.com.
            </Typography>
            <Typography variant="h5" color="textPrimary">
                Damages and issues
            </Typography>
            <Typography paragraph>
                Please inspect your order upon reception and contact us immediately if the item is defective, damaged or if you receive the wrong item, so that we can evaluate the issue and make it right.
            </Typography>
            <Typography variant="h5" color="textPrimary">
                Exceptions / non-returnable items
            </Typography>
            <Typography paragraph>
                Certain types of items cannot be returned, like perishable goods (such as food, flowers, or plants), custom products (such as special orders or personalized items), and personal care goods (such as beauty products). We also do not accept returns for hazardous materials, flammable liquids, or gases. Please get in touch if you have questions or concerns about your specific item.
            </Typography>
            <Typography paragraph>
                Unfortunately, we cannot accept returns on sale items or gift cards.
            </Typography>
            <Typography variant="h5" color="textPrimary">
                Exchanges
            </Typography>
            <Typography paragraph>
                The fastest way to ensure you get what you want is to return the item you have, and once the return is accepted, make a separate purchase for the new item.
            </Typography>
            <Typography variant="h5" color="textPrimary">
                Refunds
            </Typography>
            <Typography paragraph>
                We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method. Please remember it can take some time for your bank or credit card company to process and post the refund too.
            </Typography>
        </div>
    );
}

export default Return;
