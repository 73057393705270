import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeCartItem, addProduct, reduceCartItem } from '../../../redux/Cart/cart.actions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableCell } from '@mui/material';
import { useClasses } from '../../../customHooks';

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    cartBtn: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontSize: 18,
        color: 'red'
    },
});

const Item = (product) => {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const {
        productName,
        productThumbnail,
        productPrice,
        quantity,
        documentID
    } = product;

    const handleRemoveCartItem = (documentID) => {
        dispatch(
            removeCartItem({
                documentID
            })
        );
    };

    const handleAddProduct = (product) => {
        dispatch(
            addProduct(product)
        );
    };

    const handleReduceItem = (product) => {
        dispatch(
            reduceCartItem(product)
        );
    };

    return (
        <React.Fragment>
            <TableCell style={{width: '25%'}}>
                <Link to={`/product/${documentID}`}>
                    <img src={productThumbnail} alt={productName} width='100%'/>
                </Link>
            </TableCell>
            <TableCell style={{width: '20%'}}>
                {productName}
            </TableCell>
            <TableCell style={{width: '15%'}}>
                <RemoveCircleIcon className={classes.cartBtn} onClick={() => handleReduceItem(product)} />
                    {quantity}
                <AddCircleIcon className={classes.cartBtn} onClick={() => handleAddProduct(product)} />
            </TableCell>
            <TableCell style={{width: '30%'}}>
                ${productPrice}
            </TableCell>
            <TableCell style={{width: '10%'}}>
                <DeleteIcon className={classes.cartBtn} onClick={() => handleRemoveCartItem(documentID)} />
            </TableCell>
        </React.Fragment>
    );
}

export default Item;