import React from 'react';
import EmailPassword from '../../components/EmailPassword';

const Recovery = props => {
    return (
        <EmailPassword />
    );
}

export default Recovery;
