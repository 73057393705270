import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { checkUserSession } from './redux/User/user.actions';

// components
import AdminToolbar from './components/AdminToolbar';

// hoc high order component
import WithAuth from './hoc/withAuth';
import WithAdminAuth from './hoc/withAdminAuth';

// pages
import Homepage from './pages/Homepage';
import Search from './pages/Search';
import Registration from './pages/Registration';
import Login from './pages/Login';
import Recovery from './pages/Recovery';
import DashboardPage from './pages/DashboardPage';
import Admin from './pages/Admin';
import ProductDetails from './pages/ProductDetails';
import Cart from './pages/Cart';
import Payment from './pages/Payment';
import OrderDetailPage from './pages/OrderDetailPage';
import BlogPage from './pages/BlogPage';
import AboutUs from './pages/AboutUs';
import UnderConstruction from './pages/UnderConstruction';
import Return from './pages/Return';
import Privacy from './pages/Privacy';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Team from './pages/Team';

// layouts
import MainLayout from './layouts/MainLayout';
import AdminLayout from './layouts/AdminLayout';
import DashBoardLayout from './layouts/DashboardLayout';

const App = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());
  }, []);

  return (
    <div className="App">
      <AdminToolbar />
      <Switch>
        <Route exact path="/" render={() => (
          <MainLayout>
            <Homepage />
          </MainLayout>
        )} />
        <Route exact path="/search" render={() => (
          <MainLayout>
            <Search />
          </MainLayout>
        )} />
        <Route path="/search/:filterType" render={() => (
          <MainLayout>
            <Search />
          </MainLayout>
        )} />
        <Route path="/product/:productID" render={() => (
          <MainLayout>
            <ProductDetails />
          </MainLayout>
        )} />
        <Route path="/cart" render={() => (
          <MainLayout>
            <Cart />
          </MainLayout>
        )} />
        <Route path="/future" render={() => (
          <MainLayout>
            <UnderConstruction />
          </MainLayout>
        )} />
        <Route path="/payment" render={() => (
          <WithAuth>
            <MainLayout>
              <Payment />
            </MainLayout>
          </WithAuth>
        )} />
        <Route path="/registration" render={() => (
          <MainLayout>
            <Registration />
          </MainLayout>
        )} />
        <Route path="/login"
          render={() => (
            <MainLayout>
              <Login />
            </MainLayout>
          )} />
        <Route path="/recovery" render={() => (
          <MainLayout>
            <Recovery />
          </MainLayout>
        )} />
        <Route path="/dashboard" render={() => (
          <WithAuth>
            <DashBoardLayout>
              <DashboardPage />
            </DashBoardLayout>
          </WithAuth>
        )} />
        <Route path="/order/:orderID" render={() => (
          <WithAuth>
            <DashBoardLayout>
              <OrderDetailPage />
            </DashBoardLayout>
          </WithAuth>
        )} />
        <Route path="/admin" render={() => (
          <WithAdminAuth>
            <AdminLayout>
              <Admin />
            </AdminLayout>
          </WithAdminAuth> 
        )} />
        <Route path="/about" render={() => (
          <MainLayout>
            <AboutUs />
          </MainLayout>
        )} />
        <Route path="/team" render={() => (
          <MainLayout>
            <Team />
          </MainLayout>
        )} />
        <Route exact path="/blog/:blogName" render={() => (
          <MainLayout>
            <BlogPage />
          </MainLayout>
        )} />
        <Route path="/return" render={() => (
          <MainLayout>
            <Return />
          </MainLayout>
        )} />
        <Route path="/privacy" render={() => (
          <MainLayout>
            <Privacy/>
          </MainLayout>
        )} />
        <Route path="/contact" render={() => (
          <MainLayout>
            <Contact/>
          </MainLayout>
        )} />
        <Route path="/faq" render={() => (
          <MainLayout>
            <FAQ/>
          </MainLayout>
        )} />
      </Switch>
    </div>
  );
}

export default App;