import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#192b3d',
      light: '#2E8AA7',
      contrastText: '#F6F4Eb'
    },
    secondary: {
      main: '#faeba3',
    },
    text: {
      primary: '#192b3d',
      secondary: '#b2b19a',
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: "'Oxanium', cursive;",
  },
});

export default theme;
