import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emailSignInStart, googleSignInStart, facebookSignInStart, } from '../../redux/User/user.actions';
import { useHistory } from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '../../components/forms/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '../../assets/googleicon48.png';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useClasses } from '../../customHooks';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(14),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(1),
    },
    bottom: {
        marginTop: theme.spacing(1),
    }
});

const SignIn = props => {
    const classes = useClasses(styles);
    const { currentUser } = useSelector(mapState);
    const dispatch = useDispatch();
    const history = useHistory();
    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (currentUser) { //once the props change to true
            resetForm();
            history.push('/');
        }
    }, [currentUser]);

    const resetForm = () => {
        setValues({
            email: '',
            password: '',
            showPassword: false,
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(emailSignInStart(values));
    };

    const handleGoogleSignIn = () => {
        dispatch(googleSignInStart());
    };

    const handleFacebookSignIn = () => {
        dispatch(facebookSignInStart());
    };

    const configAccountBtn = {
        startIcon: <AccountCircleIcon />
    };

    const configFacebookBtn = {
        startIcon: <FacebookIcon />
    };

    const configGoogleBtn = {
        startIcon: <img src={GoogleIcon} width='20px' />
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={values.email}
                        onChange={handleChange('email')}
                    />
                    <FormControl variant="outlined" 
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                        />
                    </FormControl>
                    <Button
                        {...configAccountBtn}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    <Button
                        {...configGoogleBtn}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleGoogleSignIn}
                    >
                        Sign in with Google
                    </Button>
                    <Button
                        {...configFacebookBtn}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleFacebookSignIn}
                    >
                        Sign in with Facebook
                    </Button>
                    <Grid container className={classes.bottom}>
                        <Grid item xs>
                            <Link href="/recovery" color="inherit" variant="body2" >
                                {"Forgot password?"}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/registration" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}

export default SignIn;