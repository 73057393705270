import React from 'react';
import Checkout from '../../components/Checkout/Checkout';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { publishableKey } from '../../stripe/config';

const stripePromise = loadStripe(publishableKey);

const Payment = () => {
    return (
        <Elements stripe={stripePromise}>
            <Checkout />
        </Elements>
    );
}

export default Payment;