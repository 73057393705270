import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getOrderDetailsStart } from '../../redux/Orders/orders.actions';
import { useDispatch, useSelector } from 'react-redux';
import OrderDetails from '../../components/OrderDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import moment from 'moment';
import { useClasses } from '../../customHooks';

const mapState = ({ ordersData }) => ({
    orderDetails: ordersData.orderDetails
});

function Copyright() {
    return (
        <Typography variant="body2" color="textPrimary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="/">
                Everybody Preps
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const formatText = (columnName, columnValue) => {
    switch (columnName) {
        case 'orderTotal':
            return `$${columnValue}`;
        case 'orderCreatedDate':
            console.log('columnValue:', columnValue);
            return moment(columnValue['nanoseconds']).format('MM/DD/YYYY');
        default:
            return columnValue;
    }
};

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
});

const OrderDetailPage = () => {
    const { orderID } = useParams();
    const dispatch = useDispatch();
    const { orderDetails } = useSelector(mapState);
    const classes = useClasses(styles);
    console.log('orderDetails:', orderDetails);

    useEffect(() => {
        dispatch(
            getOrderDetailsStart(orderID)
        );

    }, []);

    return (
        orderDetails &&
        (<React.Fragment>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <OrderDetails order={orderDetails} />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box pt={2}>
                        Order ID: #{orderID}
                    </Box>
                    {/* <Box pt={2}>
                        Order Date: {formatText('orderCreatedDate', orderDetails.orderCreatedDate)}
                    </Box> */}
                    <Box pt={2}>
                        Total: {formatText('orderTotal', orderDetails.orderTotal)}
                    </Box>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </React.Fragment>)
    );
};


export default OrderDetailPage;
