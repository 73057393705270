import { useParams, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FeaturedPost from '../../components/Blog/FeaturedPost';
import Header from '../../components/Blog/Header';
import Sidebar from '../../components/Blog/Sidebar';
import { analytics } from '../../firebase/utils';
import MinimalistBlog from '../../components/Blog/MinimalistBlog';
import Typography from '@mui/material/Typography';
import LinkIcon from '@mui/icons-material/Link';

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import { useClasses } from '../../customHooks';
import { relativeTimeRounding } from 'moment';

// data related to the blog page
const blogs = require("../../data/blog.json");

const styles = (theme) => ({
    mainGrid: {
        marginTop: theme.spacing(8),
    },
    bottomGrid: {
        marginBottom: theme.spacing(2),
    },
    markdown: {
        ...theme.typography.body2,
    }
});

const sections = [
    { title: 'RoadTrips', url: '#' },
    { title: 'Hiking', url: '#' },
    { title: 'Camping', url: '#' },
    { title: 'Rock Climbing', url: '#' },
    { title: 'Water', url: '#' },
    { title: 'Emergency', url: '#' },
];

const featuredPosts = [
    {
        title: 'The evolving map of our journey',
        date: 'Sep 10',
        description:
            'As we promote car caming and road tripping, we tagged our journey on the map.',
        image: 'https://source.unsplash.com/random/200x400?sig=2',
        imageText: 'Random image',
    },
    {
        title: 'Prepper Mindset',
        date: 'July 11',
        description:
            'We summarize our understanding of prepping and the type of mindset we are seeking.',
        image: 'https://source.unsplash.com/random/200x400?sig=3',
        imageText: 'Image Text',
    },
];

const sidebar = blogs.sidebar;

const BlogPage = () => {
    const currentUrl = window.location.href;
    const { blogName } = useParams();
    const [postTitle, setPostTitle] = useState('');
    const [postDesc, setPostDesc] = useState('');
    const [imgText, setImgText] = useState('');
    const [img, setImg] = useState('../../blog/camping/demo3.jpg');
    const [currentPostMetaData, setCurrentPostMetaData] = useState({});

    let redirect = false;
    let hashedBlogFileLink;

    try {
        hashedBlogFileLink = require(`../../blog/${blogName}.md`);
    } catch {
        redirect = true;
    }

    const classes = useClasses(styles);

    useEffect(() => {
        if (!redirect) {
            analytics.logEvent(`${blogName}_visited`);
            setPostTitle(blogs[blogName].title);
            setPostDesc(blogs[blogName].description);
            setImgText(blogs[blogName].imgText);
            setCurrentPostMetaData({
                title: postTitle,
                description: postDesc,
                imgText: imgText,
                img: img,
            });
        } else {
            analytics.logEvent(`redurected_to_404_${blogName}`);
        }
    }, [redirect, blogName]);

    if (redirect) {
        return <Redirect to="/404" />
    }

    const content = (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                {/* <Header title="Blog" sections={sections} /> */}
                <main>
                    <Grid container direction="row" alignments="flex-start" spacing={2} className={classes.mainGrid}>
                        <Grid item xs={12} md={8} className={classes.markdown}>
                            {/* <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${mainPic})` }}/> */}
                            <Typography component="h3" variant="h3" color="inherit" gutterBottom>
                                {postTitle}
                            </Typography>
                            <EmailShareButton
                                subject={postTitle}
                                body={`Hi, I found this article on line, thinking you may like it. ${currentUrl}`}
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <FacebookShareButton
                                url={currentUrl}
                                quote={"everybody preps"}
                                hashtag={"#carcamping"}
                                description={postDesc}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                title={postTitle}
                                url={currentUrl}
                                hashtags={["#everybodypreps", "#carcamping"]}
                                related={['@EverybodyPreps']}
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <LinkIcon color="primary" fontSize="medium" onClick={() => window.prompt("Copy to clipboard: Ctrl+C, Enter", currentUrl)} />

                            {blogName === 'minimalist_suv_camping' ? <MinimalistBlog /> : null}
                        </Grid>
                        <Grid item md={4} >
                            <Sidebar
                                title={sidebar.title}
                                description={sidebar.description}
                                archives={sidebar.archives}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={4} className={classes.bottomGrid}>
                        {featuredPosts.map((post) => (
                            <FeaturedPost key={post.title} post={post} />
                        ))}
                    </Grid> */}
                </main>
            </Container>
        </React.Fragment>
    );

    return content;
}

export default BlogPage;