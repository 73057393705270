import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchProductsStart } from '../../redux/Products/products.actions';
import Product from './Product';
import FormSelect from './../forms/FormSelect';
import LoadMore from './../LoadMore';
import Grid from '@mui/material/Grid';
import { useClasses } from '../../customHooks';

const mapState = ({ productsData }) => ({
    products: productsData.products
});

const styles = (theme) => ({
    root: {
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(6),
        color: 'primary',
    },
    selector: {
        marginLeft: theme.spacing(4),
    },
    loadBtn: {
        margin: theme.spacing(2),
        color: '#32567A',
    }
});

const ProductResults = ({}) => {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const history = useHistory();
    const { filterType } = useParams();
    const { products } = useSelector(mapState);
    const { data, queryDoc, isLastPage } = products;

    useEffect(() => {
        dispatch(
            fetchProductsStart({ filterType })
        )
    }, [filterType]);

    const handleFilter = (e) => {
        const nextFilter = e.target.value;
        history.push(`/search/${nextFilter}`);
    };

    if (!Array.isArray(data)) return null;

    if (data.length < 1) {
        return (
            <div className={classes.root}>
                <p>
                    No search results.
                </p>
            </div>
        ); 
    }

    const configFilters = {
        defaultValue: filterType,
        options: [{
            name: 'Show all',
            value: ''
        }, {
            name: 'Tent',
            value: 'tent'
        }],
        handleChange: handleFilter
    };

    const handleLoadMore = () => {
        dispatch(
            fetchProductsStart({ 
                filterType, 
                startAfterDoc: queryDoc,
                persistProducts: data
             })
        )
    };

    const configLoadMore = {
        onLoadMoreEvt: handleLoadMore,
    }

    return (
        <Grid container direction="column">
            <Grid item className={classes.root} >
                <h1>
                    Browse Products
                </h1>
            </Grid>
            <Grid item className={classes.selector}>
                <FormSelect {...configFilters} />
            </Grid>

            <Grid item container>
                {data.map((product, pos) => {
                        const { productThumbnail, productName, productPrice } = product;
                        if (!productThumbnail || !productName || 
                            typeof productPrice === 'undefined') return null;
                        
                        const configProduct = {
                            ...product
                        };

                        return (
                            <Grid item xs={12} sm={4}>
                                <Product key={pos} {...configProduct} />
                            </Grid>
                        );
                    })}
            </Grid>
      
            {!isLastPage && (
                <Grid item container justify="center">
                    <Grid item xs={12} sm={4} className={classes.loadBtn}>
                        <LoadMore {...configLoadMore} />   
                    </Grid>
                </Grid>
            )}  

        </Grid>
    );
};

export default ProductResults;