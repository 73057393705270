import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Button } from '../HeroSection/Button';
import TwitterIcon from '@mui/icons-material/Twitter';
import { viewExternelLink } from '../../utils';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import './styles.css';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      Everybody Preps &nbsp;
      {new Date().getFullYear()}
      {' | St. Charles, MO, USA | info@everybodypreps.com'}
    </Typography>
  );
}

function GoBackUp() {
  return (
    <ArrowUpwardIcon color="secondary" fontSize="normal" onClick={() => window.scrollTo(0, 0)} />
  );
}

function Footer() {
  return (
    <div className='footer-container'>
      {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Receive our newsletter for best deals
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section> */}

      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items-small'>
            <h2><GoBackUp /></h2>
          </div>
          <div class='footer-link-items'>
            <Typography variant='h6' color='secondary' style={{ fontWeight: 600 }} align='center'>
              About
            </Typography>
            <Link to='/about'>
              <Typography variant='body1' color='secondary' style={{ fontWeight: 200 }} align='center'>
                Meet the Founder
              </Typography>
            </Link>
            <Link to='/faq'>
              <Typography variant='body1' color='secondary' style={{ fontWeight: 200 }} align='center'>
                FAQ
              </Typography>
            </Link>
            <Link to='/future'>
              <Typography variant='body1' color='secondary' style={{ fontWeight: 200 }} align='center'>
                Blog
              </Typography>
            </Link>
          </div>
          <div class='footer-link-items'>
            <Typography variant='h6' color='secondary' style={{ fontWeight: 600 }} align='center'>
              Legal
            </Typography>
            <Link to='/contact'>
              <Typography variant='body1' color='secondary' style={{ fontWeight: 200 }} align='center'>
                Contact Us
              </Typography>
            </Link>
            <Link to='/privacy'>
              <Typography variant='body1' color='secondary' style={{ fontWeight: 200 }} align='center'>
                Privacy Policy
              </Typography>
            </Link>
            <Link to='/return'>
              <Typography variant='body1' color='secondary' style={{ fontWeight: 200 }} align='center'>
                Return Policy
              </Typography>
            </Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <Typography variant='h6' color='secondary' style={{ fontWeight: 600 }} align='center'>
              Keep in Touch
            </Typography>
            <InstagramIcon color="secondary" fontSize="medium" onClick={() => viewExternelLink("https://www.instagram.com/everybodypreps/")} />
            <FacebookIcon color="secondary" fontSize="medium" onClick={() => viewExternelLink("https://www.facebook.com/EverybodyPrepsFB")} />
            <YouTubeIcon color="secondary" fontSize="medium" onClick={() => viewExternelLink("https://www.youtube.com/channel/UCC_FBAk_7oTn6tjTW6fTG-A")} />
            <TwitterIcon color="secondary" fontSize="medium" onClick={() => viewExternelLink("https://www.tiktok.com/@everybodypreps?lang=en")} />
          </div>
        </div>
      </div>
      <Copyright />
    </div>
  );
}

export default Footer;