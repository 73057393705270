import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import FeatureCard from './FeatureCard';
import Typography from '@mui/material/Typography';
import blogImg1 from '../../assets/tentwood.jpeg';
import blogImg2 from '../../assets/hikingriver.jpeg';
import blogImg3 from '../../assets/indoorplants.jpeg';

// Display cards for blog articles
function Cards() {
  return (
    <>
      <div className='cards'>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <Typography variant='h5' style={{ fontWeight: 600 }} align='center'>
              Check out our blog articles!
            </Typography>
            <ul className='cards__items'>
              <CardItem
                src={blogImg1}
                srcType='img'
                text='Minimalist SUV Camping Check List'
                label='camping'
                path='/blog/minimalist_suv_camping'
              />
              <CardItem
                src={blogImg2}
                srcType='img'
                text='Experience Football on Top of the Himilayan Mountains'
                label='Adventure'
                path='/blog/xxx'
              />
              <CardItem
                src={blogImg3}
                srcType='img'
                text='Ride through the Sahara Desert on a guided camel tour'
                label='Adrenaline'
                path='/blog/xxx'
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;