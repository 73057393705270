import React, { useEffect } from 'react';
import {
    Table, TableHead, 
    TableBody, TableRow, TableCell
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { setOrderDetails } from '../../redux/Orders/orders.actions';
import Title from '../Dashboard/Title';

const columns = [
    {
        id: 'productThumbnail',
        label: ''
    },
    {
        id: 'productName',
        label: 'Name'
    },
    {
        id: 'productPrice',
        label: 'Price'
    },
    {
        id: 'quantity',
        label: 'Quantity'
    }
];

const styles = {
    fontSize: '16px',
    width: '10%'
};

const formatText = (columnName, columnValue) => {
    switch(columnName) {
        case 'productPrice':
            return `$${columnValue}`;
        case 'productThumbnail':
            return <img src={columnValue} width={250} alt='product'/>
        default:
            return columnValue;
    }
}

const OrderDetails = ({ order }) => {
    const dispatch = useDispatch();
    const orderItems = order && order.orderItems;

    useEffect(() => {
        return () => {
            dispatch(
                setOrderDetails({})
            );
        }
    }, []);

    return (
        <React.Fragment>
            <Title>Order Details</Title>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((col, pos) => {
                            return (
                                <TableCell key={pos} style={styles}>
                                    {col.label}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>

                    {(Array.isArray(orderItems) && orderItems.length > 0) && orderItems.map((row, pos) => {
                        return (
                            <TableRow key={pos}>

                                {columns.map((col, pos) => {
                                    const columnName = col.id;
                                    const columnValue = row[columnName];

                                    return (
                                        <TableCell key={pos} style={styles}>
                                            {formatText(columnName, columnValue)}
                                        </TableCell>
                                    );
                        })}

                            </TableRow>
                        )
                    })}

                </TableBody>
            </Table>
        </React.Fragment>
    );
}

export default OrderDetails;