import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import featureImg3 from '../../assets/inside.png';
import featureImg2 from '../../assets/rain_guard.png';
import featureImg1 from '../../assets/overview.png';
import Container from '@mui/material/Container';
import { useClasses } from '../../customHooks';

const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(10, 0, 8),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    // paddingTop: '56.25%', // 16:9
    paddingTop: '85%',
  },
  cardContent: {
    flexGrow: 1,
  },
  title: {
    paddingTop: theme.spacing(2),
    position: 'relative',
  },
});

function Features() {
  const classes = useClasses(styles);

  return (
    <>
      <div style={{ backgroundColor: '#FBF5E6' }}>
        <Typography className={classes.title} variant='h5' style={{ fontWeight: 600,}} align='center'>
          Portable, easy-to-install, lightweight, and fits all.
        </Typography>
        <Typography className={classes.title} variant='h6' style={{ fontWeight: 400,}} align='center'>
          <i>US inventory and two-day shipping provided by Amazon</i>
        </Typography>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            <Grid item key={2} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={featureImg1}
                  title="tent overview"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6" component="h3">
                    Easy setup
                  </Typography>
                  <Typography>
                    Slide the tent on. The installation takes less than one minute.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item key={1} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={featureImg2}
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6" component="h2">
                    Front rain guard
                  </Typography>
                  <Typography>
                    Significantly improved water-proof capability. Can be put away if not in use.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item key={3} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={featureImg3}
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6" component="h2">
                    Large screen layer
                  </Typography>
                  <Typography>
                    Screen layer keeps the bugs out and lets the breeze in.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Features;