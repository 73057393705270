import userTypes from './user.types';

const INITIAL_STATE = {
    currentUser: null,
    resetPasswordSuccess: false,
    userErr: [],
    shippingAddress: {country: 'US'},
    billingAddress: {country: 'US'},
    sameAddressFlag: false,
    userCardInfo: {
        nameOnCard: '',
        cardNumber: '',
        cvv: '',
        expDate: '',
    }
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case userTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                userErr: [],
                userMsg: 'Welcome! You have successfully logged in.'
            }
        case userTypes.USER_ERROR:
            return {
                ...state,
                userErr: action.payload
            }
        case userTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordSuccess: action.payload,
                userMsg: 'You have successfully reset your password.'
            }
        case userTypes.RESET_USR_MSG:
            return {
                ...state,
                userMsg: ''
            }
        case userTypes.RESET_USER_STATE:
        case userTypes.SIGN_OUT_USER_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                userMsg: 'You have signed out your account.'
            }
        case userTypes.SET_USER_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddress: action.payload
            }
        case userTypes.SET_USER_BILLILNG_ADDRESS:
            return {
                ...state,
                billingAddress: action.payload
            }
        case userTypes.SET_SAME_ADDRESS_FLAG:
            return {
                ...state,
                sameAddressFlag: action.payload
            }
        case userTypes.SET_USER_CARD_INFO:
            return {
                ...state,
                userCardInfo: action.payload
            }
        default:
            return state;
    }
};

export default userReducer;