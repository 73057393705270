import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import June from '../../assets/june.jpeg';
import { useClasses } from '../../customHooks';

const styles = (theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 8),
        flexDirection: 'row',
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
});

const AboutUs = () => {
    const classes = useClasses(styles);

    return (
        <React.Fragment>
            <CssBaseline />
            
            <main>
                <div className={classes.heroContent}>
                    <Container maxWidth="sm" className={classes.heroContent}>
                                           
                        <div >
                            <img src={June} style={{ width: '90%' }} alt="June" />
                        </div>
                        <div>
                            <Typography variant="h5" align="left" color="textPrimary" paragraph>
                            June Schmidt
                            </Typography>
                            <Typography align="left" color="textPrimary" paragraph>
                            The founder of Everybody Preps, also in charge of the website development, product design, product development, and SEO.
                            </Typography>
                        </div> 
                    </Container>
                </div>
            </main>
        </React.Fragment>
    );
};

export default AboutUs;
