const userTypes = {
    EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    CHECK_USER_SESSION: 'CHECK_USER_SESSION',
    SIGN_OUT_USER_START: 'SIGN_OUT_USER_START',
    SIGN_OUT_USER_SUCCESS: 'SIGN_OUT_USER_SUCCESS',
    SIGN_UP_USER_START: 'SIGN_UP_USER_START',
    RESET_PASSWORD_START:'RESET_PASSWORD_START',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
    FACEBOOK_SIGN_IN_START: 'FACEBOOK_SIGN_IN_START',
    USER_ERROR: 'USER_ERROR',
    RESET_USER_STATE: 'RESET_USER_STATE',
    SET_USER_SHIPPING_ADDRESS: 'SET_USER_SHIPPING_ADDRESS',
    SET_USER_BILLILNG_ADDRESS: 'SET_USER_BILLILNG_ADDRESS',
    SET_SAME_ADDRESS_FLAG: 'SET_SAME_ADDRESS_FLAG',
    SET_USER_CARD_INFO: 'SET_USER_CARD_INFO',
    RESET_USR_MSG: 'RESET_USR_MSG', 
};

export default userTypes;