import { Typography } from '@mui/material';
import React from 'react';
import { useClasses } from '../../customHooks';

const styles = (theme) => ({
    root: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(22),
    }
});

const Contact = () => {
    const classes = useClasses(styles);

    return (
        <div className={classes.root}>
            <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                Contact 
            </Typography>
            <Typography paragraph>
                If you have any questions, or if you would like to make a suggestion or complaint, please contact us by e-mail at info@everybodypreps.com or by mail using the details provided below:
            </Typography>
            <Typography paragraph>
                Koerzell International LLC, 3170 Willow Bend Drive, Saint Charles, MO 63303, United States
            </Typography>
            <Typography paragraph>
            Last updated: 08/01/2021
            </Typography>
            <Typography paragraph>
                If you are not satisfied with our response to your complaint, you have the right to lodge your complaint with the relevant data protection authority.
            </Typography>
        </div>
    )
}

export default Contact;
