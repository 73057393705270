import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { selectCartTotal, selectCartItemsCount, selectCartItems } from '../../redux/Cart/cart.selector';
import { saveOrderHistory } from '../../redux/Orders/orders.actions';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from'react-redux';
import { useClasses } from '../../customHooks';

const addresses = ['1 Material-UI Drive', 'Reactville', 'Anytown', '99999', 'USA'];

const styles = (theme) => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    listItemText: {
        marginLeft: theme.spacing(1),
    }
});

const mapSelector = createStructuredSelector({
    total: selectCartTotal,
    itemCount: selectCartItemsCount,
    cartItems: selectCartItems,
});

const mapState = (state) => ({
    shippingAddress: state.user.shippingAddress,
    userCardInfo: state.user.userCardInfo,
});

export default function Review() {
    const classes = useClasses(styles);
    const { total, itemCount, cartItems } = useSelector(mapSelector);
    const { shippingAddress, userCardInfo } = useSelector(mapState);

    console.log('userCardInfo: ', userCardInfo);

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Order summary
            </Typography>
            <List disablePadding>
                {cartItems.map((item) => (
                    <ListItem className={classes.listItem} key={item.productName}>
                        <img src={item.productThumbnail} width='80'/>
                        <ListItemText className={classes.listItemText} primary={item.productName} secondary={item.productDesc} />
                        <Typography variant="body2">{'$'+item.productPrice}</Typography>
                    </ListItem>
                ))}
                <ListItem className={classes.listItem}>
                    <ListItemText primary="Total" />
                    <Typography variant="subtitle1" className={classes.total}>
                        {'$'+total}
          </Typography>
                </ListItem>
            </List>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Shipping
                    </Typography>
                    <Typography gutterBottom>{shippingAddress.firstName + ' ' + shippingAddress.lastName}</Typography>
                    <Typography gutterBottom>{shippingAddress.address1 + ', ' + shippingAddress.city + ', ' + shippingAddress.state + ', ' + shippingAddress.zip + ', ' + shippingAddress.country}</Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Payment details
                    </Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography gutterBottom>Card Holder</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{userCardInfo.nameOnCard}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>Card Number</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{userCardInfo.cardNumber}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>Expiration Date</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom>{userCardInfo.expDate}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
