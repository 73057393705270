import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { useDispatch, useSelector } from 'react-redux';
import { setUserShippingAddress } from '../../redux/User/user.actions';
import { useClasses } from '../../customHooks';

const styles = (theme) => ({
    regionSelector: {
      width: '100%',
      fontSize: 16,
      marginTop: theme.spacing(3),
    },
  });

const mapState = (state) => ({
    shippingAddress: state.user.shippingAddress,
});

export default function AddressForm() {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const { shippingAddress } = useSelector(mapState);
    const [stateShippingAddress, setStateShippingAddress] = useState({...shippingAddress});

    const handleShipping = evt => {
        const { name, value } = evt.target;
        setStateShippingAddress({
            ...stateShippingAddress,
            [name]: value
        });
    };

    const selectCountry = val => {
        setStateShippingAddress({
            ...stateShippingAddress,
            country: val
        });
    };

    const selectRegion = val => {
        setStateShippingAddress({
            ...stateShippingAddress,
            state: val
        });
    };

    const setShippingInRedux = () => {
        dispatch(setUserShippingAddress(stateShippingAddress));
    };

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Shipping address
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        value={stateShippingAddress.firstName}
                        fullWidth
                        autoComplete="given-name"
                        onBlur={setShippingInRedux}
                        onChange={handleShipping}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        fullWidth
                        autoComplete="family-name"
                        value={stateShippingAddress.lastName}
                        onBlur={setShippingInRedux}
                        onChange={handleShipping}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="address1"
                        name="address1"
                        label="Address line 1"
                        fullWidth
                        autoComplete="shipping address-line1"
                        value={stateShippingAddress.address1}
                        onBlur={setShippingInRedux}
                        onChange={handleShipping}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="address2"
                        name="address2"
                        label="Address line 2"
                        fullWidth
                        autoComplete="shipping address-line2"
                        value={stateShippingAddress.address2}
                        onBlur={setShippingInRedux}
                        onChange={handleShipping}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CountryDropdown 
                        className={classes.regionSelector}
                        required
                        id="country"
                        name="country"
                        label="Country"
                        value={stateShippingAddress.country}
                        onBlur={setShippingInRedux}
                        onChange={(val) => selectCountry(val)}
                        valueType="short" 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <RegionDropdown
                        required
                        className={classes.regionSelector}
                        id="state" 
                        name="state" 
                        label="State/Province/Region"
                        country={stateShippingAddress.country}
                        value={stateShippingAddress.state}
                        onBlur={setShippingInRedux}
                        onChange={(val) => selectRegion(val)}
                        countryValueType="short"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="city"
                        name="city"
                        label="City"
                        fullWidth
                        autoComplete="shipping address-level2"
                        value={stateShippingAddress.city}
                        onBlur={setShippingInRedux}
                        onChange={handleShipping}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="zip"
                        name="zip"
                        label="Zip / Postal code"
                        fullWidth
                        autoComplete="shipping postal-code"
                        value={stateShippingAddress.zip}
                        onBlur={setShippingInRedux}
                        onChange={handleShipping}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}