import React from 'react';
import MetaDecorator from '../../utils/MetaDecorator';
import { Typography } from '@mui/material';
import constructionIcon from '../../assets/construction.png';
import construction from '../../data/construction.json';
import { useClasses } from '../../customHooks';

const styles = (theme) => ({
    root: {
        marginTop: theme.spacing(10),
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
});

export default function UnderConstruction() {
    const classes = useClasses(styles);

    return (
        <div className={classes.root}>
            <MetaDecorator 
                title={construction.pageTitle} 
                description={construction.description}
                imageUrl={construction.img} 
                imageAlt={construction.metaImageAlt}
            />
            <Typography variant='h4' color='textPrimary'>
                {construction.display}
            </Typography>
            <div>
                <img src={construction.img} width='40%' alt='under construction'/>
            </div>
        </div >
    );
}