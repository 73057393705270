import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import MetaDecorator from './MetaDecorator';

function BlogMetaDecorator({ title, description, image, imageAlt, folder }) {
    const [url, setUrl] = useState("");

    useEffect(() => {
        import(`../blog/${folder}/${image}`).then((imageLink) => {
            setUrl(imageLink.default);
        });
    }, [folder, image]);

    return (
        <MetaDecorator imageUrl={url} title={title} description={description} imageAlt={imageAlt}/>
    )
}

BlogMetaDecorator.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    folder: PropTypes.string.isRequired,
};

export default BlogMetaDecorator;
