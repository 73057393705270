import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { setUserBillingAddress, setSameAddressFlag, setUserCardInfo } from '../../redux/User/user.actions';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { useClasses } from '../../customHooks';

const initialAddress = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: 'US',
    zip: '',
};

const styles = (theme) => ({
    regionSelector: {
      width: '100%',
      fontSize: 16,
      marginTop: theme.spacing(3),
    },
    title: {
        marginTop: theme.spacing(3),
    }
  });

const mapState = (state) => ({
    billingAddress: state.user.billingAddress,
    shippingAddress: state.user.shippingAddress,
    sameAddressFlag: state.user.sameAddressFlag,
    userCardInfo: state.user.userCardInfo,
});

export default function PaymentForm() {
    const dispatch = useDispatch();
    const classes = useClasses(styles);
    const { shippingAddress, billingAddress, sameAddressFlag, userCardInfo, } = useSelector(mapState);
    const [stateBillingAddress, setStateBillingAddress] = useState({...billingAddress});
    const [sameAddress, setSameAddress] = useState(sameAddressFlag);
    const [cardInfo, setCardInfo] = useState({...userCardInfo});

    useEffect(() => {
        async function setAll(address) {
            await setStateBillingAddress(address);
            dispatch(setUserBillingAddress(address));
            dispatch(setSameAddressFlag(sameAddress));
        }
        if (sameAddress) {
            setAll(shippingAddress);
        } else {
            setAll(initialAddress);
        }
    }, [sameAddress]);


    const handleBilling = evt => {
        const { name, value } = evt.target;
        setStateBillingAddress({
            ...stateBillingAddress,
            [name]: value
        });
    };

    const handleCardInfo = evt => {
        const { name, value } = evt.target;
        setCardInfo({
            ...cardInfo,
            [name]: value
        });
    };

    const selectCountry = val => {
        setStateBillingAddress({
            ...stateBillingAddress,
            country: val
        });
    };

    const selectRegion = val => {
        setStateBillingAddress({
            ...stateBillingAddress,
            state: val
        });
    };

    const setBillingInRedux = () => {
        dispatch(setUserBillingAddress(stateBillingAddress));
    };

    const handleChange = (evt) => {
        setSameAddress(evt.target.checked);
    };

    const setCardInfoRedux = () => {
        dispatch(setUserCardInfo(cardInfo));
    };

    const configCardElement = {
        iconStyle: 'solid',
        style: {
            base: {
                fontSize: '16px'
            }
        },
        hidePostalCode: true
    };

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Payment method
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField 
                        required 
                        id="nameOnCard" 
                        name="nameOnCard"
                        label="Name on card" 
                        fullWidth 
                        autoComplete="cc-name" 
                        onChange={handleCardInfo}
                        onBlur={setCardInfoRedux}
                        value={cardInfo.nameOnCard}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="cardNumber"
                        name="cardNumber"
                        label="Card number"
                        fullWidth
                        autoComplete="cc-number"
                        onChange={handleCardInfo}
                        onBlur={setCardInfoRedux}
                        value={cardInfo.cardNumber}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField 
                        required 
                        id="expDate" 
                        label="Expiry date" 
                        name="expDate"
                        helperText="Four digits to represent the expiration date"
                        fullWidth
                        autoComplete="cc-exp" 
                        onChange={handleCardInfo}
                        onBlur={setCardInfoRedux}
                        value={cardInfo.expDate}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="cvv"
                        label="CVV"
                        name="cvv"
                        helperText="Last three digits on signature strip"
                        fullWidth
                        autoComplete="cc-csc"
                        onChange={handleCardInfo}
                        onBlur={setCardInfoRedux}
                        value={cardInfo.cvv}
                    />
                </Grid>    
            </Grid>
            <Typography variant="h6" gutterBottom className={classes.title}>
                Billing address
            </Typography>
            <FormControlLabel
                control={<Checkbox color="primary" name="saveAddress" value="yes" checked={sameAddress} onChange={handleChange}/>}
                label="Use the same address as the shipping address"
            />
            {!sameAddress && 
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="address1"
                            name="address1"
                            label="Address line 1"
                            fullWidth
                            autoComplete="shipping address-line1"
                            value={stateBillingAddress.address1}
                            onBlur={setBillingInRedux}
                            onChange={handleBilling}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="address2"
                            name="address2"
                            label="Address line 2"
                            fullWidth
                            autoComplete="shipping address-line2"
                            value={stateBillingAddress.address2}
                            onBlur={setBillingInRedux}
                            onChange={handleBilling}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CountryDropdown 
                            className={classes.regionSelector}
                            required
                            id="country"
                            name="country"
                            label="Country"
                            onChange={(val) => selectCountry(val)}
                            valueType="short" 
                            value={stateBillingAddress.country}
                            onBlur={setBillingInRedux}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RegionDropdown
                            required
                            className={classes.regionSelector}
                            id="state" 
                            name="state" 
                            label="State/Province/Region"
                            country={stateBillingAddress.country}
                            onChange={(val) => selectRegion(val)}
                            countryValueType="short"
                            value={stateBillingAddress.state}
                            onBlur={setBillingInRedux}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="city"
                            name="city"
                            label="City"
                            fullWidth
                            autoComplete="shipping address-level2"
                            value={stateBillingAddress.city}
                            onBlur={setBillingInRedux}
                            onChange={handleBilling}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="zip"
                            name="zip"
                            label="Zip / Postal code"
                            fullWidth
                            autoComplete="shipping postal-code"
                            value={stateBillingAddress.zip}
                            onBlur={setBillingInRedux}
                            onChange={handleBilling}
                        />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}