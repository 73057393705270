import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import { firebaseConfig } from './config';

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const projectStorage = firebase.storage();
export const fbTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: 'select_account' });
export const FacebookProvider = new firebase.auth.FacebookAuthProvider();
export const analytics = firebase.analytics();
//FacebookProvider.addScope('user_birthday');

export const handleUserProfile = async ({ userAuth, additionalData }) => {
    if (!userAuth) return;
    const { uid } = userAuth;

    const userRef = firestore.doc(`users/${uid}`);
    const snapshot = await userRef.get();

    if (!snapshot.exists) {
        const { displayName, email, photoURL } = userAuth;
        const timestamp = fbTimestamp();
        const userRoles = ['user'];

        try {
            await userRef.set({
                displayName,
                email,
                photoURL,
                createdDate: timestamp,
                userRoles,
                ...additionalData
            });
        } catch(err) {
            //console.log(err);
        }
    }
    return userRef;
};

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged( userAuth => {
            unsubscribe();
            resolve(userAuth);
        }, reject)
    })
}

export const viewTentonAmz = () => {
    analytics.logEvent('view_item');
        window.open(
            'https://www.amazon.com/dp/B099Z5MD3C?maas=maas_adg_0C2093A3A51B8F2B689718BB8C8F52A7_afap_abs&ref_=aa_maas&ref=myi_title_dp',
            '_blank' // <- This is what makes it open in a new window.
          );
}
  