import axios from 'axios';

export const checkUserIsAdmin = currentUser => {
    if (!currentUser || !Array.isArray(currentUser.userRoles)) return false;

    const { userRoles } = currentUser;
    if ( userRoles.includes('admin')) return true;

    return false;
}

export const apiInstance = axios.create({
    //baseURL: 'http://localhost:5001/everybodypreps-website/us-central1/api'
    baseURL: 'https://us-central1-everybodypreps-website.cloudfunctions.net/api'
});

export const viewExternelLink = (externalLink) => {
    window.open(
        externalLink,
        '_blank' // <- This is what makes it open in a new window.
        );
}