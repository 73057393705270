import React from 'react';
import UserProfile from './../UserProfile';
import { useDispatch, useSelector } from 'react-redux';
import { signOutUserStart } from '../../redux/User/user.actions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListIcon from '@mui/icons-material/List';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import PeopleIcon from '@mui/icons-material/People';
import { useHistory } from 'react-router-dom';

const mapState = ({ user }) => ({
  currentUser: user.currentUser
})

const VerticalNav = () => {
  const { currentUser } = useSelector(mapState);
  const history = useHistory();

  const configUserProfile = {
    currentUser
  };

  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(signOutUserStart());
    history.push('/');
  };

  const goToShopNow = () => {
    history.push('/search');
  }

  const goToShopingCart = () => {
    history.push('/cart')
  }

  const goToHomePage = () => {
    history.push('/');
  }

  const goToOrders = () => {
    history.push('/dashboard');
  }

  return (
    <div>
      <UserProfile {...configUserProfile} />
      <List>
        <ListItem button onClick={goToHomePage}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home Page" />
        </ListItem>
        <ListItem button onClick={goToOrders}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="Orders History" />
        </ListItem>
        <ListItem button onClick={goToShopNow}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Product List" />
        </ListItem>
        <ListItem button onClick={goToShopingCart}>
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Shopping Cart" />
        </ListItem>
        <ListItem button onClick={signOut}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      </List>  
    </div>
  );
};

export default VerticalNav;