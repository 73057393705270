import React, { useEffect } from 'react';
import HeroSection from '../../components/HeroSection';
import Cards from '../../components/Cards';
import Features from '../../components/Features';
import Videos from '../../components/Videos';
import MetaDecorator from '../../utils/MetaDecorator';
import { analytics } from '../../firebase/utils'

const homePage = require("../../data/homePage.json");
const Homepage = props => {

    useEffect(() => {
        analytics.logEvent("homepage_visited");
    });
    
    return (
        // <section className="homepage">
        <div styles={{backgroundColor: '#FBF5E6'}}>
            <MetaDecorator title={homePage.pageTitle} description={homePage.description} />
            <HeroSection />
            <Features />
            <Videos />
            {/* <Cards/> */}
        </div>
    );
};

export default Homepage;