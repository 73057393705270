import React from 'react';
import About from '../../components/AboutUs';

const AboutUs = props => {
    return (
        <section>
            <About/>
        </section>
    );
};

export default AboutUs;