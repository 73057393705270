import React from 'react';
import Button from '../forms/Button';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const LoadMore = ({
    onLoadMoreEvt = () => { },
}) => {

    const configButton = {
        startIcon: <AutorenewIcon />,
        color: '#32567A',
    };

    return (
        <Button 
            {...configButton}
            onClick={() => onLoadMoreEvt()}>
            Load More
        </Button>
    );
};

export default LoadMore;