import React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Dashboard/Title';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useClasses } from '../../customHooks';

const columns = [
    {
        id: 'orderCreatedDate',
        label: 'Order Date'
    },
    {
        id: 'documentID',
        label: 'Order ID'
    },
    {
        id: 'orderTotal',
        label: 'Amount'
    }
];

const formatText = (columnName, columnValue) => {
    switch (columnName) {
        case 'orderTotal':
            return `$${columnValue}`;
        case 'orderCreatedDate':
            return moment(columnValue['nanoseconds']).format('MM/DD/YYYY');
        default:
            return columnValue;
    }
};

function preventDefault(event) {
    event.preventDefault();
}

const styles = (theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    orderItem: {
        cursor: 'pointer'
    }
});

const Orders = ({ orders }) => {
    const classes = useClasses(styles);
    const history = useHistory();

    return (
        <React.Fragment>
            <Title>Recent Orders</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {columns.map((column, pos) => {
                            const { label } = column;

                            return (
                                <TableCell key={pos}>
                                    {label}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(Array.isArray(orders) && orders.length > 0) && orders.map((row, pos) => {
                        const { documentID } = row;

                        return (
                            <TableRow
                                key={pos}
                                onClick={() => history.push(`/order/${documentID}`)}
                            >
                                {columns.map((column, pos) => {
                                    const columnName = column.id;
                                    const columnValue = row[columnName];
                                    const formatedText = formatText(columnName, columnValue);

                                    return (
                                        <TableCell key={pos} className={classes.orderItem}>
                                            {formatedText}
                                        </TableCell>
                                    )
                                })}

                            </TableRow>
                        )
                    })}

                </TableBody>
            </Table>
            <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={preventDefault}>
                    See more orders
                </Link>
            </div>
        </React.Fragment>
    );
}

export default Orders;