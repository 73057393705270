import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useHistory } from 'react-router-dom';
import img1 from '../../assets/hikingriver.jpeg';
import img2 from '../../assets/widehiking.jpeg';
import img3 from '../../assets/van.jpeg';
import camper from '../../assets/my_camper_van_project.png';
import brand from '../../assets/Brand_Story.png';
import { useClasses } from '../../customHooks';
import theme from '../../theme';

const styles = theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 8),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
});

const AboutUs = () => {
    const classes = useClasses(styles);
    const history = useHistory();

    const handleShop = () => {
        // history.push('/search');
        history.push('/product/M4pgs09EERrJsFX6Ih6X');
    }

    const handleTeam = () => {
        history.push('/team');
    }

    return (
        <React.Fragment>
            <CssBaseline />

            <main>
                {/* Hero unit */}
                <div className={classes.heroContent}>
                    <Container maxWidth="md">
                        <Typography component="h3" variant="h4" align="center" color="textPrimary" gutterBottom>
                            Meet the Founder
                        </Typography>
                        <Typography align="left" color="textPrimary" paragraph>
                            Hi! My name is June. I have always loved the
                            idea of travelling and living a free lifestyle. I remember when I was a kid, just like other girls,
                            I recieved a Barbie doll as a gift. The idea came to my mind that I would like my Barbie friend to have my dream life.
                            I found a chunk of styrofoam at home and built her a house. I then installed wheels on
                            the bottom of the “house,” turning it into an amphibious camper, thinking that my doll
                            could now travel the world, by road or by sea.
                        </Typography>

                        <Typography align="left" color="textPrimary" paragraph>
                            When I grew up, my family was a modest, middle-class family, and travel was a luxury for us. We only went on
                            vacation once before I went to college. After college, I quickly fell in love with all sorts of outdoor activities: camping, biking, hiking, and
                            especially rock climbing. In 2013, I came to the US to pursue a graduate degree in
                            Charlotte, NC. I was deeply amazed by the natural beauty of this country. I told myself that
                            one day I would travel all over it and explore. Every weekend, I would go out
                            for camping or rock climbing.
                        </Typography>

                        <Typography align="left" color="textPrimary" paragraph>
                            As a student, I did not have much money. In 2017,
                            I had the idea of building my own camper van—a
                            project I started with a 1992 GMC Vandura. I cleaned everything out of the van and installed
                            insulation, a pull-out bed, wood flooring, and walls. I also installed solar panels on top of
                            the vehicle to power the electrical system inside the van. I was quite satisfied with this
                            cozy tiny-house-on-wheels. I even spent one semester living out of it, full-time. It was a
                            fun experience!
                        </Typography>
                        <div>
                            <img src={camper} style={{ width: '100%' }} alt="My Camper Van Project" />
                        </div>
                        <Typography align="left" color="textPrimary" paragraph>
                            However, there were several obvious drawbacks. The van gets poor mileage, is slow,
                            and is hard to maneuver. As I drove it through the Smoky Mountains, the narrow and
                            winding roads were nerve-racking. I gradually gave up on using it for my road trips. One
                            weekend in 2019, I went on a rock-climbing trip to the Horse Shoe Canyon Ranch in
                            Arkansas with a climbing partner. Instead of using a hotel, we parked in a public area and slept in the back
                            of the SUV, a Subaru Outback. It was not a particularly pleasant night. It was too hot to
                            sleep in the car with the windows rolled up, but rolling the windows down meant
                            welcoming in mosquitoes and all kinds of bugs.
                        </Typography>

                        <Typography align="left" color="textPrimary" paragraph>
                            Everybody Preps started in the year 2021, when everyone curtailed their travel. I got
                            more free time to think about how I would like to travel in the future. That&#39;s how the idea
                            for this SUV tent came about. I discussed with my husband. We drew some
                            design sketches, talked to several factories, and confirmed that, yes, it is possible to
                            produce something like what I had imagined!
                        </Typography>
                        <Typography align="left" color="textPrimary" paragraph>
                            This is the story of how we got here. We are excited to present to you our first
                            official product: the Armadillo V1 SUV Tent. We designed this tent to be the kind of product
                            that we would personally love to use. Today, we’re traveling everywhere with it. Check out our social media
                            to follow our journeys.
                        </Typography>

                        <Typography align="left" color="textPrimary" paragraph>
                            We want to keep growing our brand and expanding our product line, thereby promoting outdoor recreation. We welcome people with the same enthusiasm and vision.
                        </Typography>

                        <Typography component="h3" variant="h4" align="center" color="textPrimary" gutterBottom>
                            Brand Mission
                        </Typography>
                        <Typography align="left" color="textPrimary" paragraph>
                            As we continuously grow, we keep in mind that our brand mission is to develop <b>high-quality</b>, <b>practical</b> and <b>affordable</b> products.
                            We do almost everything by ourselves to keep the costs low and products affordable, without sacrificing the quality.
                        </Typography>
                        <Grid container spacing={2} justify="center">
                            <Grid item key={3} xs={12} sm={8} md={6} align='center'>
                                <img src={brand} style={{ width: '100%' }} alt="Brand Mission" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify="center">
                            <Grid item key={3} xs={12} sm={8} md={6} align='center'>
                                <Typography variant="h6" color="textPrimary">
                                    <i>"Be prepared for your next adventure!"</i>
                                </Typography>
                            </Grid>
                        </Grid>

                        <div className={classes.heroButtons}>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleShop}>
                                        Go To Shopping
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" color="primary" onClick={handleTeam}>
                                        Meet the Team
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                    <Grid container spacing={4}>
                        <Grid item key={3} xs={12} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={img3}
                                    title="Image title"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Quality
                                    </Typography>
                                    <Typography>
                                        We personally oversee the design of everything we sell. We care about the quality and durability of the product.
                                        We take quality seriously.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key={1} xs={12} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={img1}
                                    title="Image title"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Practical
                                    </Typography>
                                    <Typography>
                                        We build features in our products based on what our customers tell us they want, and based on what we in our own experience have found most useful and practical.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key={2} xs={12} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={img2}
                                    title="Image title"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Affordable
                                    </Typography>
                                    <Typography>
                                        We prioritize afforadbility by comparing production and shipping providers. We work with high-quality partners to optimize customer experience.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Container>
            </main>
        </React.Fragment>
    );
};

export default AboutUs;
