import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useClasses } from '../../customHooks';

const styles = (theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
  },
  cardMedia: {
    height: '10%',
    paddingTop: '30%', // 16:9
  },
  cardContent: {
    height: '90%',
    flexGrow: 1,
  },
});

function FeatureCard(props) {
  const classes = useClasses(styles);

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={props.src}
        title="Feature 1"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h5" style={{ fontWeight: 600 }} align='left' component="h2">
          {props.text}
        </Typography>
        <Typography>
          {props.desc}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default FeatureCard;