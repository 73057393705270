import React from 'react';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addProduct } from "../../../redux/Cart/cart.actions";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import companyLogo from '../../../assets/logo.png';
import { useClasses } from '../../../customHooks';

const styles = (theme) => ({
    root: {
        margin: theme.spacing(4),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: '#192b3d',
    },
    subtitle: {
        color: '#192b3d',
    },
});

const Product = (product) => {
    const classes = useClasses(styles);
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();
    const {
        documentID,
        productThumbnail,
        productName,
        productPrice,
        productDesc,
        productDetails,
    } = product;

    if (!documentID || !productThumbnail || !productName || typeof productPrice === 'undefined') return null;

    const handleAddToCart = (product) => {
        if (!product) return;

        dispatch(
            addProduct(product)
        );
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const copyLinkToClipboard = (documentID) => {
        const urlLink = document.createElement('a');
        urlLink.href = `${window.location.origin}/product/${documentID}`;
        console.log('urlLink:', urlLink);
        const range = document.createRange();
        range.selectNode(urlLink);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        const successful = document.execCommand('copy');
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                classes={{ subheader: classes.subtitle }}
                avatar={
                    <Avatar src={companyLogo} />
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title={productName}
                subheader={`$${productPrice}`}
            />
            <Link to={`/product/${documentID}`}>
                <CardMedia
                    className={classes.media}
                    image={productThumbnail}
                    title={productName}
                />
            </Link>
            <CardContent>
                <Typography variant="body1" color="#192b3d" component="p">
                    {productDesc}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="add to shopping cart" onClick={() => handleAddToCart(product)}>
                    <AddShoppingCartIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon onClick={() => copyLinkToClipboard(documentID)}/>
                </IconButton>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography paragraph variant="body1" color="primary">Product Details:</Typography>
                    <Typography variant="body2" color="primary" component="p">
                        <p dangerouslySetInnerHTML={{ __html: productDetails }} />
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default Product;