import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductStart, setProduct } from './../../redux/Products/products.actions';
import { addProduct } from '../../redux/Cart/cart.actions';
import Button from '../forms/Button';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AmazonIcon from '../../assets/amazonlogo.png';
import ImageGallery from 'react-image-gallery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import './styles.scss';
import { analytics } from '../../firebase/utils';
import { useClasses } from '../../customHooks';

const mapState = state => ({
    product: state.productsData.product
})

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginTop:theme.spacing(10),
    },
    title: {
        textAlign: "center",
    },
    desc: {
        marginTop: theme.spacing(4),
    },
    price: {
        textAlign: "right",
        marginRight: theme.spacing(2),
    },
    addToCart: {
        marginBottom: theme.spacing(1),
    }
});

let images = [];

const ProductCard = ({ }) => {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const history = useHistory();
    const { productID } = useParams();
    const { product } = useSelector(mapState);
    const {
        productThumbnail,
        productName,
        productPrice,        
        productPriceDiscount,
        productDesc,
        productDetails,
        productImages,
        productVideo,
        externalLink,
    } = product;

    useEffect(() => {
        dispatch(
            fetchProductStart(productID)
        );

        return () => {
            dispatch(
                setProduct({})
            );
        }
    }, []);

    const configAddToCartBtn = {
        type: 'button',
        startIcon: <AddShoppingCartIcon />,
    }

    const configAmzBtn = {
        startIcon: <img src={AmazonIcon} width='25px' alt='amazon button'/>
    }

    const handleAddToCart = (product) => {
        if (!product) return;
        dispatch(
            addProduct(product)
        );
        history.push('/cart');
    }

    const handleViewAmz = (externalLink) => {
        analytics.logEvent('view_item');
        window.open(
            externalLink,
            '_blank' // <- This is what makes it open in a new window.
          );
    }

    if (productImages) {
        productImages.forEach(img => {
            images.push({ original: img, thumbnail: img });
        });
    }

    return (
        <div className={classes.root}>
            <Typography variant='h5' className={classes.title}>
                {productName}
            </Typography>
            <Grid container spacing={2} justify="center">
                <Grid item xs={12} sm={6}>
                    <div className="gallery">
                        {images && <ImageGallery items={images} />}
                    </div>
                </Grid>
                <Grid container item xs={12} sm={6}>
                    <Grid item xs={12}>
                        <Typography paragraph className={classes.desc}>
                            {productDesc}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <span
                            dangerouslySetInnerHTML={{ __html: productDetails }} />
                    </Grid>
                    <Grid item xs={12} className={classes.price}>
                        <Typography variant="h6">
                            Price: ${productPrice}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.addToCart}>
                            <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product)}>
                                Add to cart
                            </Button>
                        </div>
                        <div className={classes.addToCart}>
                            <Button {...configAmzBtn} onClick={() => handleViewAmz(externalLink)}>
                                Buy from Amazon
                            </Button>
                        </div>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    );
}

export default ProductCard;