import { takeLatest, call, all, put } from 'redux-saga/effects';
import userTypes from './user.types';
import { signInSuccess, signOutUserSuccess, resetPasswordSuccess, userError } from './user.actions';
import { auth, handleUserProfile, getCurrentUser, GoogleProvider, FacebookProvider } from '../../firebase/utils';
import { handleResetPasswordAPI } from './user.helpers';
import _ from "lodash";

export function* getSnapshotFromUserAuth(user, additionalData = {}) {
    try {
        const userRef = yield call(handleUserProfile, { userAuth: user, additionalData });
        const snapshot = yield userRef.get();
        yield put(
            signInSuccess({
                id: snapshot.id,
                ...snapshot.data()
            })
        );
    } catch (err) {
        console.log(err);
    }
}

export function* emailSignIn({ payload: { email, password } }) {
    try {
        const { user } = yield auth.signInWithEmailAndPassword(email, password);
        yield getSnapshotFromUserAuth(user);
    } catch (err) {
        console.log("Email sign in error: ", err);
    }
}

export function* isUserAuthenticated() {
    try {
        const userAuth = yield getCurrentUser();
        if (!userAuth) return;
        yield getSnapshotFromUserAuth(userAuth);
    } catch (err) {
        console.log(err);
    }
}

export function* onCheckUserSession() {
    yield takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onEmailSignInStart() {
    yield takeLatest(userTypes.EMAIL_SIGN_IN_START, emailSignIn);
}

export function* signOutUser() {
    try {
        yield auth.signOut();
        yield put(
            signOutUserSuccess()
        );
    } catch (err) {
        console.log('err:', err);
    }
}

export function* onSignOutUserStart() {
    yield takeLatest(userTypes.SIGN_OUT_USER_START, signOutUser);
}

export function* signUpUser({ payload: {
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    receiveEmail,
} }) {

    let err = {};
    if (password !== confirmPassword) {
        err['confirmPassword'] = 'Passwords Don\'t match';
    }

    if (password.length < 6) {
        err['password'] = 'Password must be longer than 6 characters';
    }

    const re = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/;
    if (email.match(re)) {
        err['email'] = 'Email is not the correct format';
    }

    if (!_.isEmpty(err)) {
        yield put(
            userError(err)
        );
        return;
    }

    try {
        const { user } = yield auth.createUserWithEmailAndPassword(email, password);
        const additionalData = { firstName, lastName, receiveEmail };
        yield getSnapshotFromUserAuth(user, additionalData)

    } catch (error) {
        console.log("Register Error:", error);
        err['register'] = error.message;
        yield put(
            userError(err)
        );
    }
}

export function* onSignUpUserStart() {
    yield takeLatest(userTypes.SIGN_UP_USER_START, signUpUser);
}

export function* resetPassword({ payload: { email } }) {
    try {
        yield call(handleResetPasswordAPI, email);
        yield put(
            resetPasswordSuccess()
        );
    } catch (err) {
        yield put(
            userError(err)
        );
    }
}

export function* onResetPasswordStart() {
    yield takeLatest(userTypes.RESET_PASSWORD_START, resetPassword);
}

export function* googleSignIn() {
    try {
        const { user } = yield auth.signInWithPopup(GoogleProvider);
        yield getSnapshotFromUserAuth(user);
    } catch (err) {
        console.log('Error signing in with google:', err);
    }
}

export function* onGoogleSignInStart() {
    yield takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn);
}

export function* facebookSignIn() {
    try {
        const { user, credential, } = yield auth.signInWithPopup(FacebookProvider);
        yield getSnapshotFromUserAuth(user);
    } catch (err) {
        console.log('Error signing in with facebook:', err);
    }
}

export function* onFacebookSignInStart() {
    yield takeLatest(userTypes.FACEBOOK_SIGN_IN_START, facebookSignIn);
}

export default function* userSagas() {
    yield all([
        call(onEmailSignInStart),
        call(onCheckUserSession),
        call(onSignOutUserStart),
        call(onSignUpUserStart),
        call(onGoogleSignInStart),
        call(onFacebookSignInStart),
        call(onResetPasswordStart)
    ])
}
