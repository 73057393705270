import React from 'react';
import { alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import Button from '@mui/material/Button';
//import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
//import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import MoreIcon from '@mui/icons-material/MoreVert';
import { useSelector, useDispatch } from 'react-redux';
import { signOutUserStart, clearUserMessage } from '../../redux/User/user.actions';
import { Link, useHistory } from 'react-router-dom';
import { selectCartItemsCount } from '../../redux/Cart/cart.selector';
import Logo from '../../assets/oldlogo.png';
import { viewExternelLink } from '../../utils';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Alert from '@mui/material/Alert';
import { useClasses } from '../../customHooks';

const styles = (theme) => ({
    alert: {
        position: 'relative',
        width: '100%',
        marginTop: theme.spacing(8),
        '& > * + *': {
            width: '100%',
            marginTop: theme.spacing(8),
        },
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logo: {
        maxWidth: 240,
        display: 'none',
        [theme.breakpoints.up('xs')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
});

const mapState = (state) => ({
    currentUser: state.user.currentUser,
    userMsg: state.user.userMsg,
    totalNumCartItems: selectCartItemsCount(state)
});

const ITEM_HEIGHT = 48;

const Header = props => {
    const classes = useClasses(styles);
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [startMenuAnchorEl, setStartMenuAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isStartMenuOpen = Boolean(startMenuAnchorEl);

    const dispatch = useDispatch();
    const { currentUser, userMsg, totalNumCartItems } = useSelector(mapState);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleClick = (event) => {
        setStartMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setStartMenuAnchorEl(null);
    };

    const goToDashboard = () => {
        history.push('/dashboard');
    }

    const handleSignOut = () => {
        dispatch(signOutUserStart());
    }

    const handleLogin = () => {
        history.push('/login');
        handleMobileMenuClose();
    }

    const handleShopCart = () => {
        history.push('/cart');
        handleMobileMenuClose();
    }

    const goToHome = () => {
        history.push('/');
        handleClose();
    }

    const goToShopNow = () => {
        // history.push('/search');
        history.push('/product/M4pgs09EERrJsFX6Ih6X');
        handleClose();
    }

    const goToBlog = () => {
        history.push('/blog/minimalist_suv_camping');
        handleClose();
    }

    const goToAboutUs = () => {
        history.push('/about');
        handleClose();
    }

    const goToFAQ = () => {
        history.push('/faq');
        handleClose();
    }

    const clearUserMsg = () => {
        dispatch(clearUserMessage());
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={goToDashboard}>My account</MenuItem>
            <MenuItem onClick={handleSignOut}>Log Out</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleShopCart}>
                <IconButton aria-label="show number of items in the cart" color="inherit">
                    <Badge badgeContent={totalNumCartItems} color="secondary">
                        <ShoppingCartIcon />
                    </Badge>
                </IconButton>
                <p>Shopping Cart</p>
            </MenuItem>
            {currentUser ? (
                <MenuItem onClick={handleProfileMenuOpen}>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            ) : (
                <MenuItem onClick={handleLogin}>
                    <IconButton
                        aria-label="log in"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <p>Log In</p>
                </MenuItem>
            )}
        </Menu>
    );

    return (
        <div>
            { userMsg ? (
                <div className={classes.alert} >
                    <Alert severity="success" onClose={clearUserMsg}>{userMsg}</Alert>
                </div>
            ) : null }
    
            <div className={classes.grow}>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="more"
                            area-haspopup="true"
                            onClick={handleClick}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={startMenuAnchorEl}
                            keepMounted
                            open={isStartMenuOpen}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 5.5,
                                    width: '20ch',
                                    backgroundColor: '#E8E7D2',
                                },
                            }}
                        >
                            <MenuItem key={'HOME'} onClick={goToHome}>
                                {'HOME'}
                            </MenuItem>
                            <MenuItem key={'SHOP NOW'} onClick={goToShopNow}>
                                {'SHOP NOW'}
                            </MenuItem>
                            <MenuItem key={'ABOUT US'} onClick={goToAboutUs}>
                                {'ABOUT US'}
                            </MenuItem>
                            <MenuItem key={'FAQ'} onClick={goToFAQ}>
                                {'FAQ'}
                            </MenuItem>
                            <MenuItem key={'BLOG'} onClick={goToBlog}>
                                {'BLOG'}
                            </MenuItem>
                        </Menu>
                        <Link to="/">
                            <img src={Logo} className={classes.logo} alt="EP Logo" />
                        </Link>
                        {/* serch function below */}
                        {/* <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div> */}
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            {/* <IconButton aria-label="show number of items in cart" color="inherit" onClick={handleShopCart}>
                                <Badge badgeContent={totalNumCartItems} color="secondary">
                                    <ShoppingCartIcon />
                                </Badge>
                            </IconButton>

                            {currentUser ?
                                (<IconButton
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>) :
                                (<Button onClick={handleLogin} color='secondary'>
                                    Log In
                                </Button>
                                )} */}
                                <InstagramIcon color="secondary" fontSize="medium" onClick={() => viewExternelLink("https://www.instagram.com/everybodypreps/")} />
                                <FacebookIcon color="secondary" fontSize="medium" onClick={() => viewExternelLink("https://www.facebook.com/EverybodyPrepsFB")} />
                        </div>
                        <div className={classes.sectionMobile}>
                            {/* <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton> */}                                
                            <InstagramIcon color="secondary" fontSize="medium" onClick={() => viewExternelLink("https://www.instagram.com/everybodypreps/")} />
                            <FacebookIcon color="secondary" fontSize="medium" onClick={() => viewExternelLink("https://www.facebook.com/EverybodyPrepsFB")} />
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
            </div>
        </div>
    );
}

Header.defaultProps = {
    currentUser: null
};

export default Header;