import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import mainPage1 from '../../assets/coverpage1.png';
import mainPage2 from '../../assets/coverpage2.png';
import mainPage3 from '../../assets/coverpage3.png';
import mainPage4 from '../../assets/coverpage4.png';
import IconButton from '@mui/material/IconButton';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { viewTentonAmz } from '../../firebase/utils';
// import mainVideo from '../../assets/video/cover.mp4';
import { Button } from './Button';
import './styles.scss';
import { useClasses } from '../../customHooks';

export default function HeroSection() {
    const history = useHistory();
    const [pic, setPic] = useState(mainPage1);
    const [clickedIconNum, setClickedIconNum] = useState('1')

    const handleShop = () => {
        // history.push('/search');
        history.push('/product/M4pgs09EERrJsFX6Ih6X');
    }

    const handleAboutBtn = () => {
        history.push('/about');
    }

    const changeSelectedIcon = (event) => {
        const id = event.target.id;
        setClickedIconNum(id);
        switch (id) {
            case '1':
                setPic(mainPage1);
                return;
            case '2':
                setPic(mainPage2);
                return;
            case '3':
                setPic(mainPage3);
                return;
            case '4':
                setPic(mainPage4);
                return;
        }
    }

    const styles = (theme) => ({
        iconContainer: {
          "&.MuiButtonBase-root": { padding: theme.spacing(0.2) },
          '&:hover': {
            color: '#192b3d',
            backgroundColor: '#faeba3',
            },
        }
      });

    useEffect(() => {
        console.log('picNum:', pic);
    }, [pic, clickedIconNum]);

    const classes = useClasses(styles);

    return (
        <div className='hero-container'>
            {/* <video src={mainVideo} autoPlay loop muted /> */}
            <img className='hero-img' src={pic} />
            <p>URBAN | OUTDOOR | SURVIVAL</p>

            <div className='hero-btns'>
                <Button
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                    onClick={viewTentonAmz}
                >
                    Get Your Tent Today
                </Button>
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                    onClick={handleAboutBtn}
                >
                    About Us <i className='far fa-play-circle' />
                </Button>
            </div>
            <div className='hero-img-btns'>
                <IconButton color="secondary" className={classes.iconContainer}>
                    {clickedIconNum === '1' ? <RadioButtonCheckedIcon fontSize="medium" /> : <RadioButtonUncheckedIcon id='1' fontSize="medium" onClick={changeSelectedIcon} />}
                </IconButton>
                <IconButton color="secondary" className={classes.iconContainer}>
                    {clickedIconNum === '2' ? <RadioButtonCheckedIcon fontSize="medium" /> : <RadioButtonUncheckedIcon id='2' fontSize="medium" onClick={changeSelectedIcon} />}
                </IconButton>
                <IconButton color="secondary" className={classes.iconContainer}>
                    {clickedIconNum === '3' ? <RadioButtonCheckedIcon fontSize="medium" /> : <RadioButtonUncheckedIcon id='3' fontSize="medium" onClick={changeSelectedIcon} />}
                </IconButton>
                <IconButton color="secondary" className={classes.iconContainer}>
                    {clickedIconNum === '4'?<RadioButtonCheckedIcon fontSize="medium" />: <RadioButtonUncheckedIcon id='4' fontSize="medium" onClick={changeSelectedIcon} />}
                </IconButton>
            </div>
        </div>
    )
}
